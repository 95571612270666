import React, { useState, useEffect, createRef } from "react";

import ReCAPTCHA from "react-google-recaptcha";
import { MailIcon, PhoneIcon, CheckIcon } from "@heroicons/react/solid";

import { initialFormFields } from "../utils/utils";
import { bearerToken, captchaInfoUrl, sendMailerUrl } from "../../api/mailer";

const Form = (props) => {
  const {
    title,
    description,
    phone,
    email,
    submitButton,
    submitButtonSuccess,
  } = props;

  const recaptchaRef = createRef();

  const [formFields, setFormFields] = useState(initialFormFields);
  const [formIsSent, setFormIsSent] = useState(false);
  const [recaptchaResultKey, setRecaptchResultKey] = useState();

  const [siteKey, setSiteKey] = useState("");

  const [isVerified, setIsVerified] = useState(false);

  const getCaptchaInfo = async () => {
    const resp = await fetch(captchaInfoUrl, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken,
      },
    }).catch((err) => {
      new Error(err);
    });

    const data = await resp.json();

    setSiteKey(data.site_key);
  };

  useEffect(() => {
    getCaptchaInfo();
  }, []);

  const setEmail = (event) => {
    setFormFields({ ...formFields, email: event.target.value });
  };
  const setName = (event) => {
    setFormFields({ ...formFields, fullName: event.target.value });
  };
  const setPhone = (event) => {
    setFormFields({ ...formFields, phone: event.target.value });
  };
  const setMessage = (event) => {
    setFormFields({ ...formFields, message: event.target.value });
  };

  const onSubmit = () => {
    fetch(sendMailerUrl, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken,
      },
      body: JSON.stringify({
        email: formFields.email,
        name: formFields.name,
        phone: formFields.phone,
        message: formFields.message,
        subject: "Redmine.pro callback form",

        recaptcha: recaptchaResultKey,
      }),
    })
      .catch((err) => {
        new Error(err);
        setFormIsSent(false);
      })
      .then((res) => {
        setFormIsSent(true);
        setFormFields(initialFormFields);
      });
  };
  return (
    <div className="relative bg-white" id="contacts">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              {title}
            </h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              {description}
            </p>
            <dl className="mt-8 text-base text-gray-500">
              <div className="mt-6">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex">
                  <PhoneIcon
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-3">{phone}</span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <MailIcon
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-3">{email}</span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <form action="#" method="POST" className="grid grid-cols-1 gap-y-6">
              <div>
                <label htmlFor="full-name" className="sr-only">
                  Full name
                </label>
                <input
                  type="text"
                  name="full-name"
                  id="full-name"
                  autoComplete="name"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                  placeholder="Full name"
                  onBlur={setName}
                />
              </div>
              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  onBlur={setEmail}
                  autoComplete="email"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                  placeholder="Email"
                />
              </div>
              <div>
                <label htmlFor="phone" className="sr-only">
                  Phone
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  autoComplete="tel"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                  placeholder="Phone"
                  onBlur={setPhone}
                />
              </div>
              <div>
                <label htmlFor="message" className="sr-only">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border border-gray-300 rounded-md"
                  placeholder="Message"
                  defaultValue={""}
                  onBlur={setMessage}
                />
              </div>
              {siteKey && formFields.email && !isVerified && (
                <ReCAPTCHA
                  ref={recaptchaRef}
                  badge={"inline"}
                  size={"compact"}
                  sitekey={siteKey}
                  onErrored={() => setIsVerified(false)}
                  onExpired={() => setIsVerified(false)}
                  onChange={() => {
                    setRecaptchResultKey(recaptchaRef.current.getValue());
                    setIsVerified(true);
                  }}
                />
              )}
              <div>
                {!isVerified && !formFields.email && (
                  <button
                    type="button"
                    onClick={onSubmit}
                    disabled={!formFields.email}
                    className="mt-3 w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-cyan-700 focus:ring-amber-400 disabled:bg-gray-200 sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0"
                  >
                    {submitButton}
                  </button>
                )}
                {isVerified && (
                  <>
                    {!formIsSent || !isVerified ? (
                      <button
                        type="button"
                        onClick={onSubmit}
                        disabled={!formFields.email}
                        className="mt-3 w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-cyan-700 focus:ring-amber-400 disabled:bg-gray-200 sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0"
                      >
                        {submitButton}
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="mt-3 w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 bg-green-600 hover:bg-green-700 focus:ring-green-500 sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0"
                      >
                        {submitButtonSuccess}
                        <CheckIcon className="h-6 w-6 ml-2 " />
                      </button>
                    )}
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
