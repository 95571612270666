import React from "react";

const Prices = (props) => {
  const { children, title, description } = props;
  return (
    <div
      className="max-w-7xl mx-auto py-24 px-4 bg-white sm:px-6 lg:px-8"
      id="pricing"
    >
      <h2 className="text-3xl font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">
        {title}
      </h2>
      <p className="mt-6 max-w-2xl text-xl text-gray-500">{description}</p>

      {/* Tiers */}
      <div className="mt-24 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
        {children}
      </div>
    </div>
  );
};

export default Prices;
