import React from "react";
import Logotype from "../../images/flat-logo.svg";
import Link from "gatsby-link";

const Header = (props) => {
  const { children } = props;
  return (
    <header>
      <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:pl-8">
        <div className="flex justify-start lg:w-0 lg:flex-1">
          <a href="#">
            <img className="h-8 w-auto sm:h-10" src={Logotype} alt="" />
          </a>
        </div>

        <div className=" hidden md:flex items-center justify-end md:flex-1 lg:w-0 gap-6">
          {children}
        </div>
        <Link
          to="/authorization"
          className=" whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-sky-500 hover:bg-sky-600"
        >
          Sign in
        </Link>
      </div>
    </header>
  );
};

export default Header;
