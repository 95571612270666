import React from "react";
import { CheckIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const PriceItem = (props) => {
  const {
    name,
    price,
    recommended,
    description,
    ramCapacity,
    coresAmount,
    ssdCapacity,
    callToAction,
  } = props;
  return (
    <div
      key={name}
      className="relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col"
    >
      <div className="flex-1">
        <h3 className="text-xl font-semibold text-gray-900">{name}</h3>
        {recommended ? (
          <p className="absolute top-0 py-1.5 px-4 bg-sky-500 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">
            Most popular
          </p>
        ) : null}
        <p className="mt-4 flex items-baseline text-gray-900">
          <span className="text-5xl font-extrabold tracking-tight">
            ${price}
          </span>
          <span className="ml-1 text-xl font-semibold">Per month</span>
        </p>
        <p className="mt-6 text-gray-500">{description}</p>

        {/* Feature list */}
        <ul role="list" className="mt-6 space-y-6">
          <li className="flex">
            <CheckIcon
              className="flex-shrink-0 w-6 h-6 text-sky-500"
              aria-hidden="true"
            />
            <span className="ml-3 text-gray-500">{ramCapacity} RAM</span>
          </li>
          <li className="flex">
            <CheckIcon
              className="flex-shrink-0 w-6 h-6 text-sky-500"
              aria-hidden="true"
            />
            <span className="ml-3 text-gray-500">{coresAmount} CPU</span>
          </li>
          <li className="flex">
            <CheckIcon
              className="flex-shrink-0 w-6 h-6 text-sky-500"
              aria-hidden="true"
            />
            <span className="ml-3 text-gray-500">{ssdCapacity} SSD</span>
          </li>
        </ul>
      </div>

      <a
        href="#"
        className={classNames(
          recommended
            ? "bg-sky-500 text-white hover:bg-sky-600"
            : "bg-sky-50 text-sky-700 hover:bg-sky-100",
          "mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium"
        )}
      >
        {callToAction}
      </a>
    </div>
  );
};

export default PriceItem;
