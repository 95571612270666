import React from "react";

const TrustItem = (props) => {
  const { src, description } = props;
  return (
    <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
      <img className="max-h-12" src={src} alt={description} />
    </div>
  );
};

export default TrustItem;
