import React from "react";

const HeaderLink = (props) => {
  const { target, name } = props;
  return (
    <a
      href={target}
      className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
    >
      {name}
    </a>
  );
};
export default HeaderLink;
