import * as React from "react";
import "../styles/base.css";
import Header from "../components/sections/Header";
import HeaderLink from "../components/elements/HeaderLink";
import FirstScreen from "../components/sections/FirstScreen";
import Trusts from "../components/sections/Trusts";
import TrustItem from "../components/elements/TrustItem";
import Features from "../components/sections/Features";
import FeatureItem from "../components/elements/FeatureItem";
import Prices from "../components/sections/Prices";
import PriceItem from "../components/elements/PriceItem";
import Form from "../components/sections/Form";
import Footer from "../components/sections/Footer";
import BraveIcon from "../images/bravedevs.png";
import GodaIcon from "../images/godagrannen.png";
import DvigexIcon from "../images/dvigex.png";
import TestCenterIcon from "../images/testcenter.png";
import EmotianIcon from "../images/emotian.png";
import ElfsterIcon from "../images/elfster.png";
import Wally from "../images/wally.png";
import PinpointerIcon from "../images/pinpointer.png";

const IndexPage = () => {
  return (
    <div className="bg-white">
      <Header>
        <HeaderLink name={"Features"} target={"#features"} />
        <HeaderLink name={"Pricing"} target={"#pricing"} />
        <HeaderLink name={"Contacts"} target={"#contacts"} />
      </Header>
      <FirstScreen
        title="Private, fast and reliable"
        subtitle="GitLab hosting"
        description="Code repository management system with its own wiki, bug tracking system, CI/CD pipeline and other features"
        callToAction="Contact us"
      />
      <Trusts title="TRUSTED BY" className="items-center">
        <TrustItem
          src={BraveIcon}
          description={"brave developers"}
        />
        <TrustItem
          src={GodaIcon}
          description={"GodaGrannen"}
        />
        <TrustItem
          src={DvigexIcon}
          description={"Dvigex"}
        />
        <TrustItem
          src={TestCenterIcon}
          description={"Test center"}
        />
        <TrustItem
          src={EmotianIcon}
          description={"Emotian"}
        />
        <TrustItem
          src={ElfsterIcon}
          description={"Elfster"}
        />
        <TrustItem
          src={Wally}
          description={"Wally"}
        />
        <TrustItem
          src={PinpointerIcon}
          description={"Pinpointer"}
        />
      </Trusts>
      <Features
        title="Our features"
        id="features"
        subtitle="everything you need"
        description="Gitt.one has the capacity to help plan, organize, and manage resource tools and develop resource estimates. Depending on the sophistication of the software, it can manage estimation and planning, scheduling, cost control and budget management, resource allocation, collaboration software, communication, decision-making, quality management, time management and documentation or administration systems."
      >
        <FeatureItem
          name={"Fast, private & secure"}
          description={
            "For each Gitt.one instance, we provide a private server to run on. You are getting an optimized instance set on the fastest server you can get"
          }
        />
        <FeatureItem
          name={"Datacenters around the world"}
          description={
            "We will easily deploy your Gitt.one instance anywhere around the globe. No matter where you are: USA, Europe, China or Australia, you will get your server just around the corner"
          }
        />
        <FeatureItem
          name={"Automatic backups"}
          description={
            "Every night we run system backups, securely collected to another geolocation and can be restored in a blink of an eye"
          }
        />
        <FeatureItem
          name={"Fully configured instance"}
          description={
            "With us, you will get instance fully managed to your project’s needs. Including your own domain name and ability to use any Gitt.one version, you would like"
          }
        />
        <FeatureItem
          name={"Migration from any repository"}
          description={
            "We can easily migrate your project from any hosting and make changes by your needs"
          }
        />
        <FeatureItem
          name={"CI/CD"}
          description={
            "We set up as many Continuous Integration Runners as you need, as well as Continuous Deployments within your workflow"
          }
        />
      </Features>

      <Prices
        id="prices"
        title="Pricing plans for teams of all sizes"
        description="Choose an affordable plan that's packed with the best features for engaging your team and flexible project management"
      >
        <PriceItem
          name={"Basic"}
          price={99}
          description={"Recommended for up to 20 users"}
          ramCapacity={"4GB"}
          coresAmount={"2x"}
          ssdCapacity={"60GB"}
          callToAction={"Bill monthly"}
        />
        <PriceItem
          name={"Starter"}
          price={299}
          recommended={true}
          description={"Recommended for up to 100 users"}
          ramCapacity={"32GB"}
          coresAmount={"8x"}
          ssdCapacity={"120GB"}
          callToAction={"Bill monthly"}
        />
        <PriceItem
          name={"Ultimate"}
          price={990}
          description={"Recommended for up to 1000 users"}
          ramCapacity={"128GB"}
          coresAmount={"32x"}
          ssdCapacity={"1000GB"}
          callToAction={"Bill monthly"}
        />
      </Prices>
      <Form
        phone="+7912 744 0881"
        email="support@gitt.one"
         submitButton="Send"
        submitButtonSuccess="Success"
        description="Would you like a Fast, Secure & Fully managed Redmine instance that
          you can rely on?"
        id="contacts"
      />
    </div>
  );
};

export default IndexPage;
